.footer {
  background: white !important;
  height: 60px;
  width: 100%;
  // margin-top: 10%;
  // margin-bottom: -3% !important;
  // box-shadow: 10px 0px 10px black;
  // box-shadow: 10px 0px 10px 2px black;
  box-shadow: 0px -13px 38px rgba(59, 74, 87, 0.06);
}
.footerLinks {
  display: flex;
  align-items: center;
  width: 50%;
  height: 100%;
  justify-content: flex-end;
  margin-left: 45%;
}

.footerDivision {
  // margin-top: -2%;
  // margin-left: 83%;
  width: 1px !important;
  height: 45%;
  background-color: gray !important;
}
.terms {
  // margin-top: 1.3% !important;
  // margin-left: 70% !important;
  // height: 40% !important;
}
.privacy {
  // margin-top: -5% !important;
  // height: 40% !important;
  // margin-left: 85% !important;
}
