.prebooking_type_dropdown_container {
  width: 48%;
  margin-bottom: 4%;
  // margin-left: 25%;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  label {
    font-size: 12px;
    font-weight: 600;
  }
  .b_s_dropdown {
    border-width: 2px;
    outline: none;
    padding: 0px;
    height: 52px;
    margin: 0px;
  }
  .b_s_dropdown_item {
    padding: 10px 20px;
    span {
      font-size: 14px;
      font-weight: 500;
    }
  }
}

.replanning_detail_row {
  display: flex;
  width: 100%;
  justify-content: space-between;
  label {
    font-size: 12px;
    font-weight: 600;
  }
  .b_s_input_control {
    width: 48%;
    margin-bottom: 20px;
    & > div {
      &:first-child {
        min-height: unset !important;
        box-sizing: border-box;
        height: 52px;
        border-width: 2px;
        background: #f6f7f9;
        box-shadow: 0 2px 0 #c2c9d1;
      }
    }
  }
  .b_s_dropdown_container {
    width: 48%;
    .b_s_dropdown {
      border-width: 2px;
      outline: none;
      padding: 0px;
      height: 52px;
      margin: 0px;
    }
    .b_s_dropdown_item {
      padding: 10px 20px;
      span {
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
}

.truck_dropdown_container {
  width: 48%;
  margin-bottom: 4%;
  .truck_dropdown {
    border-width: 2px;
    outline: none;
    padding: 0px;
    height: 52px;
    margin: 0px;
  }
  .truck_dropdown_item {
    padding: 10px 20px;
    span {
      font-size: 14px;
      font-weight: 500;
    }
  }
}
.replanningPrebookDropdown {
  & + ul {
    max-height: 170px;
  }
}
body
  > div.sc-m9cfk7-1.bHBLUE
  > div:nth-child(3)
  > div
  > div
  > div
  > div.sc-m9cfk7-4.lgrOsu
  > div
  > div
  > form
  > div:nth-child(5)
  > div
  > div
  > div
  > div
  > div
  > div
  > div.SingleDatePicker_picker.SingleDatePicker_picker_1.SingleDatePicker_picker__directionLeft.SingleDatePicker_picker__directionLeft_2
  > div
  > div
  > div
  > div.DayPicker_focusRegion.DayPicker_focusRegion_1
  > div.DayPicker_transitionContainer.DayPicker_transitionContainer_1.DayPicker_transitionContainer__horizontal.DayPicker_transitionContainer__horizontal_2 {
  margin-top: -185%;
}

body
  > div.sc-m9cfk7-1.bHBLUE
  > div:nth-child(3)
  > div
  > div
  > div
  > div.sc-m9cfk7-4.lgrOsu
  > div
  > div
  > form
  > div:nth-child(4)
  > div
  > div
  > div
  > div
  > div
  > div
  > div.SingleDatePicker_picker.SingleDatePicker_picker_1.SingleDatePicker_picker__directionLeft.SingleDatePicker_picker__directionLeft_2 {
  margin-top: -265%;
}

body
  > div.sc-m9cfk7-1.bHBLUE
  > div:nth-child(3)
  > div
  > div
  > div
  > div.sc-m9cfk7-4.lgrOsu
  > div
  > div
  > form
  > div:nth-child(4)
  > div
  > div
  > div
  > div
  > div
  > div
  > div.SingleDatePicker_picker.SingleDatePicker_picker_1.SingleDatePicker_picker__directionLeft.SingleDatePicker_picker__directionLeft_2
  > div
  > div
  > div
  > div.DayPicker_focusRegion.DayPicker_focusRegion_1
  > div.DayPicker_transitionContainer.DayPicker_transitionContainer_1.DayPicker_transitionContainer__horizontal.DayPicker_transitionContainer__horizontal_2 {
  height: 330px !important;
}
