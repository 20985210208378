.replanningSection {
  .replanningSection {
    width: 100%;
  }

  .replanningUpperSection {
    width: 100%;
    // height: 900px;
    // height: 280px;
    // height: 1140px;
    // border-radius: 10px;
    // padding-bottom: 0px;
    background-color: #f6f7f9;
  }

  .inputDevision {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    // height: 80%;
  }

  .facilityDivision {
    margin: 25px 0px;
    // padding-left: 30px;
    // padding-top: 30px;
    // padding-right: 30px;
    padding: 30px;
    width: 340px;

    .facilityDivisionDropdown {
      background-color: white;
      box-shadow: none;
      border: none;
      outline: none;
      padding: 2rem 1.5rem;
      margin: 0px;
      height: 72px;
      box-shadow: inset 0px -2px 0px #c2c9d1;
    }
  }

  .datePickerDivision {
    // margin-top: 5%;
    // background-color: white;
    // height: 30%;
    // margin-left: 2%;
    width: 300px;
    // margin-top: 55px;
    margin-left: 18px;
    height: 72px;
  }

  .datePickerDivision > div > div {
    background: white !important;
  }

  .startTime {
    width: 300px;
    background: #ffffff;
    margin-top: 55px;
    height: 72px;
    left: 872px;
    margin-left: 50px;
    border-radius: 16px;
  }

  .inputDevision > div.startTime > div > div {
    width: 300px;
    height: 72px;
    box-shadow: inset 0px -2px 0px #c2c9d1;
    border-radius: 16px;
  }

  .startButton {
    // position: relative;
    width: 132px;
    height: 56px;
    // top: -10px;
    margin-left: 45px;
    // width: 200px;
    // // background: #ffffff;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    // // margin-top: 55px;
    // height: 72px;
    // left: 872px;
    // margin-left: 50px;
  }

  // Calender css
  .replanningDateInput {
    width: 100%;
    margin-bottom: 20px;
    .gqNyYv {
      min-height: unset;
      // height: 52px;
      box-sizing: border-box;
    }
    .hHEWwT {
      border-width: 2px;
    }
    table {
      left: 30px !important;
      width: 90% !important;
      height: 90% !important;
      tr {
        td {
          vertical-align: middle;
          height: 32px !important;
          width: 35px !important;
          &::after {
            width: 35px !important;
            height: 35px !important;
            margin-top: -2px !important;
            margin-left: -2.6px !important;
          }
          &.CalendarDay__today {
            &::after {
              // left: -2px !important;
              // top: -2px !important;
              // width: 30px !important;
            }
          }
        }
      }
    }
    .DayPicker_weekHeader_ul {
      .DayPicker_weekHeader_li {
        width: 49.3px !important;
      }
    }
    .bPDDWb {
      .DayPicker {
        width: 370px !important;
        & > div {
          & > div {
            width: 100% !important;
          }
        }
        .DayPicker_transitionContainer {
          width: 100% !important;
          height: 380px !important;
        }
      }
      .CalendarMonth_caption strong {
        margin-left: -30px !important;
      }
      .CalendarDay__today:not(.CalendarDay__selected_span):not(.CalendarDay__selected) {
        // width: 16% !important;
        // height: 33px !important;
      }
      .SingleDatePicker_picker {
        left: -65px !important;
      }
    }
  }

  // .startButton {
  //   justify-content: center;
  //   margin-left: 0%;
  //   margin-top: 10%;
  // }

  .legend {
    // background-color: red;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    // width: 100%;
    // // margin-top: -30px;
    // height: 40px;
    // padding: 5px;
    // background-color: red;
    // padding-top: 20px;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 30px;
  }
  .containerBooking {
    // position: relative;
    margin-left: 20px;
    width: 30px;
    height: 30px;
    border-radius: 7px;
    background-color: #581930;
  }

  .containerBookingText {
    margin-top: 6px;
    margin-left: 10px;
    font-family: Montserrat;
    color: #3b4a57;
    font-weight: 600;
    font-size: 1rem;
  }

  .specialBooking {
    margin-left: 20px;
    width: 30px;
    height: 30px;
    border-radius: 7px;
    background-color: $orange;
  }

  .specialBookingText {
    margin-top: 6px;
    margin-left: 10px;
    font-family: Montserrat;
    color: #3b4a57;
    font-weight: 600;
    font-size: 1rem;
  }

  .deletedSlots {
    margin-left: 20px;
    width: 30px;
    height: 30px;
    border-radius: 7px;
    background-color: #e6ebf1;
    border: solid 1px;
    border-color: black;
  }

  .deletedSlotsText {
    margin-top: 6px;
    margin-left: 10px;
    font-family: Montserrat;
    color: #3b4a57;
    font-weight: 600;
    font-size: 1rem;
  }

  .replanningLowerSection {
    margin-top: -5%;
    background-color: #f6f7f9;
    padding: 20px;
  }
  .inboundSlots {
    position: relative;
    display: flex;
    background: white;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    width: 94%;
    margin: 3%;
    margin-top: 4%;
    padding-bottom: 30px;
  }

  .inboundSlotTitle {
    position: relative;
    width: 250px;
    height: 32px;
    // top: 15px;
    // left: 30px;
    padding: 20px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 128%;
    color: #3b4a57;
  }

  .inboundSlotsDatatable {
    position: relative;
    display: flex;
    // align-items: center;
    margin-left: -25%;
    margin-top: 10% !important;
    // margin-right: 2%;
    vertical-align: middle !important;
    width: 100%;
    // margin: 20px;
    // justify-content: center;
    // background-color: #f6f7f9 !important;
    background-color: white !important;
    // margin-top: 80px;
  }

  .inboundSlotsDatatable .checkedRow {
    background-color: #dddedf !important;
    color: black !important;
  }

  .dockRow {
    background-color: white;
    // box-shadow: white !important;
    box-shadow: 2px 3px 2px 2px white;
  }

  .checkBoxContainer {
    background-color: white !important;
    // box-shadow: white !important;
    box-shadow: 2px 3px 2px 2px white;
  }
  //Hiding first checkbox
  #hideCheckbox {
    display: contents;
  }

  .inboundSlotTitleAndButton {
    // display: flex;
    // display: flex;
    // flex-direction: row;
    // flex-wrap: wrap;
    // align-items: center;

    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    height: 80%;
    width: 100%;
    // background-color: red;
  }

  .collapseDiv {
    // margin-left: 10%;
    // margin-left: -100px;
  }

  .collapse {
    // box-shadow: white;
    display: flex;
    // justify-content: center;
    align-items: center;
    border: solid 2px;
    position: relative;
    // margin-right: 4%;
    margin-top: 30px;
    margin-left: 86%;
    // justify-content: flex-end;
    // margin-right: auto;
    height: 30px;
    background: white;
    border-color: white;
    cursor: pointer;
    position: absolute;

    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 155.6%;
    color: #e84f1e;
  }
  .slotReplanningTableAndSwitches {
    display: flex;
    flex-direction: column;
    width: 94%;
    margin-left: 25%;
  }

  .dataNotPlanned {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-left: -18%;
    margin-top: 10%;
    font-family: Montserrat !important;
    font-style: normal;
    font-weight: normal;
    font-size: 26px;
    line-height: 150%;
    color: #e84f1e;
    width: 100%;
  }

  .preBookSlotNote {
    position: relative;
    margin-left: -23.5%;
    margin-top: 3%;
    font-family: Montserrat !important;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    color: #3b4a57;
    width: 50%;
  }

  .inboundSlotsToggles {
    display: flex;
    align-items: center;
    margin-left: -210px;
    margin-top: 20px;
    width: 80%;
  }

  .shiftingSlotButton {
    margin-left: 25px;
  }

  .outboundSlotsToggles {
    margin-left: -260px;
    margin-top: 20px;
  }

  .outboundSlots {
    // position: relative;
    display: flex;
    // flex-direction: "column";
    background: white;
    width: 94%;
    margin: 3%;
    padding-bottom: 30px;
  }

  .outboundSlotTitleAndButton {
    display: flex;
  }

  .outboundSlotTitle {
    position: relative;
    width: 300px;
    height: 32px;
    top: 15px;
    left: 30px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 128%;
    color: #3b4a57;
  }

  .outBoundTableAndSwitches {
    display: flex;
    flex-direction: column;
  }

  .outboundSlotsDatatable {
    position: relative;
    display: flex;
    align-items: center;
    margin-left: -40%;
    margin-top: 15% !important;
    margin-right: 2%;
    vertical-align: middle !important;
    // width: 110%;
    // margin: 20px;
    // justify-content: center;
    // background-color: #f6f7f9 !important;
    background-color: white !important;
    // margin-top: 80px;
  }

  .outPreBookSlotNote {
    position: relative;
    margin-left: -40%;
    margin-top: 3%;
    font-family: Montserrat !important;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    color: #3b4a57;
  }

  .addOutboundDockButtonDivision {
    display: flex;
    // align-items: center;
  }

  .addOutboundDockButton {
    // box-shadow: white;
    display: flex;
    border: solid 2px;
    position: relative;
    margin-left: 30px;
    margin-top: -15px;
    justify-content: flex-end;
    margin-right: auto;
    height: 30px;
    background-color: #f6f7f9;
    border-color: #f6f7f9;
    cursor: pointer;
    position: absolute;

    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 155.6%;
    color: #e84f1e;
  }
  .availableSlots {
    display: flex;
    // justify-content: center;
    // align-items: center;
    position: fixed;
    margin-top: 90px;
    margin-left: -6%;
    position: relative;
    display: flex;
    background: red;
  }

  .availableSlotInfo {
    background-color: #edeae6;
    box-shadow: 0px 15px 22px rgba(0, 0, 0, 0.06),
      0px 3px 8px rgba(0, 0, 0, 0.08), 0px 1px 1px rgba(0, 0, 0, 0.08),
      0px 3px 1px rgba(0, 0, 0, 0.04);
    color: #4a051e;
    padding-top: 17px;
    vertical-align: middle !important;
    justify-content: center !important;
    font-weight: bold;
    border-radius: 15px;
    height: 50px;
    width: 80px;
  }

  .availableSlot {
    justify-content: center;
    align-items: center;
    background: #0051c2;
    color: white;
    padding: 10px;
    width: 80px;
    height: 45px;
    margin-left: 17.5px;
    border-radius: 6px;
  }
}

.css-ja5taz-MuiTooltip-tooltip {
  background-color: rgba(49, 48, 48, 0.92) !important;
  border-radius: 4px;
  color: #fff;
  font-family: "Montserrat" !important;
  padding: 6px 10px !important;
  font-size: 14.5px !important;
  // max-width: 340px !important;
  max-width: 3236px !important;
  margin: 2px;
  word-wrap: break-word;
  // font-weight: 500;
}

.replanningDateInput .CalendarDay__blocked_out_of_range, 
.replanningDateInput .CalendarDay__blocked_out_of_range:active,
.replanningDateInput .CalendarDay__blocked_out_of_range:hover
{
  background: none !important;
  border: none !important;
  color: #C2C9D1 !important;
}