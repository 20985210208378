.docDiv{
.sub_menu_dock {
  margin-left: -25px;

  .sub_menu_links_dock {
    font-family: inherit;
    color: #4a051e;
    border: transparent;
    cursor: pointer;
    background: transparent;
    font-size: 15px;
    font-weight: 500;
    padding: 0px;
    padding-top: 5px;
    padding-bottom: 10px;
    margin-left: 25px;
    margin-top: 10px;
    transition: 0.4s;
    box-sizing: border-box;
    &.active {
      border-bottom: 2.8px solid $orange;
    }
  }
}

.dock_profile_input_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
  .dock_disable_input_control {
    width: 48%;
    margin-bottom: 20px;
    & > div {
      &:first-child {
        min-height: unset !important;
        box-sizing: border-box;
        height: 67px;
        border-width: 2px;
        // background: #f6f7f9;
        background: lightgrey;
        // box-shadow: 0 2px 0 #c2c9d1;
      }
    }
    
    
    // & label {
    //   color: red !important;
    //   font: bold;
    // }
  }
  
  .dock_input_control {
    width: 48%;
    margin-bottom: 20px;
  }
  .dock_profile_dropdown_container {
    width: 48%;
    margin-bottom: 20px;
    .dock_profile_dropdown {
      border-width: 2px;
      outline: none;
      padding: 0px;
      height: 67px;
      margin: 0px;
    }
    .dock_profile_dropdown_item {
      padding: 10px 20px;
      span {
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
}

.dock-Data {
  max-height: calc(100vh - 9rem) !important;
  min-width: 40rem !important;
  max-width: 90rem !important;
  // width: -webkit-max-content;
  // width: -moz-max-content;
  // width: max-content;
  // min-height: auto;
  // min-height: initial;
  // height: auto;
  // height: initial;
  border-radius: 1.5rem;
  overflow: hidden;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.dockBox{
    padding-top: 0rem;
    padding-left: 5rem;
    width: 50%;
}

#pr_id_2_content_3_expanded > td > div > div > div > div > div:hover{
background: #4a051e !important;
}
}