.slot-planning-page {
  width: 100%;
  // height: 2000px;
  // height: 100vh;
  min-height: 700px;
  // overflow: auto;
  // min-height: 30vh;
  .links {
    margin: 20px 0px;
    display: flex;
    align-items: center;
    span {
      color: #0051c2;
      font-size: 14px;
      font-weight: 600;
    }
    .arrow {
      margin: 0px 10px;
    }
  }

  .slot_planning_title {
    font-size: 30px;
    font-weight: 700;
    color: black;
  }

  .tabs {
    margin-top: 5%;
    display: flex;
    align-items: center;
  }

  .planning {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    color: #3b4a57;
    height: 33px;
    background-color: transparent;
    border: transparent;
    cursor: pointer;
    border-bottom: #3b4a57 solid 2px;
  }

  .disablePlan {
    font-family: Montserrat;
    font-style: normal;
    // font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    color: #3b4a57;
    height: 33px;
    background-color: transparent;
    border: transparent;
    cursor: pointer;
    // border-bottom: #3b4a57 solid 2px;
  }

  .replanning {
    font-family: Montserrat;
    font-style: normal;
    font-size: 16px;
    line-height: 16px;
    font-weight: 600;
    color: #3b4a57;
    margin-left: 2%;
    height: 33px;
    background-color: transparent;
    border: transparent;
    cursor: pointer;
    border-bottom: #3b4a57 solid 2px;
  }

  .disableReplan {
    font-family: Montserrat;
    font-style: normal;
    font-size: 16px;
    line-height: 16px;
    color: #3b4a57;
    margin-left: 2%;
    height: 33px;
    background-color: transparent;
    border: transparent;
    cursor: pointer;
    // border-bottom: #3b4a57 solid 2px;
  }

  .PlanningUpperSection {
    width: 100%;
    // height: 280px;
    // height: 1140px;
    // border-radius: 10px;
   // padding-bottom: 35px;
    background-color: #f6f7f9;
    // background-color: white;
  }

  .planningLowerSection {
    width: 100%;
    // height: 860px;
    padding-bottom: 20px;
    background-color: #f6f7f9;
  }

  .tabContent {
    width: 100%;
    // min-height: 220px;
    // max-height: 1162px;
    // height: 1162px;
    // background-color: #f6f7f9;
    background-color: white;
  }

  .smallTabContent {
    width: 100%;
    // min-height: 220px;
    // max-height: 1162px;
    // height: 350px;
    // background-color: #f6f7f9;
    background-color: white;
  }

  .planningSection {
    width: 100%;
    // height: 380px;
  }

  .selectedFaciltyDropdown {
    margin: 25px 0px;
    padding: 30px 30px 30px 25px;
    width: 355px;

    .selectedFaciltyDropdownButton {
      background-color: white;
      box-shadow: none;
      border: none;
      outline: none;
      padding: 2rem 1.5rem;
      margin: 0px;
      height: 72px;
      box-shadow: inset 0px -2px 0px #c2c9d1;
    }
  }

  .selectorDivision {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    height: 80%;
    // border-bottom: 1px solid #c2c9d1;
  }

  .dateDivision {
    text-align: center;
    background: #ffffff;
    width: 295px;
    // margin-top: 55px;
    margin-left: 4px;
    height: 72px;
    box-shadow: inset 0px -2px 0px #c2c9d1;
    border-radius: 16px;

    .dateDivisionContainer {
      // margin-left: -90px;
      .dateDivisionTitle {
        margin-left: -30px;
        font-size: 13px;
        color: rgba(0, 0, 0, 0.6);
      }
      .upcomingFromDate {
        margin-top: 10px;
        font-weight: bold;
        color: rgba(0, 0, 0, 0.6);
        margin-left: -70px;
      }
      .upcomingToDate {
        margin-top: 10px;
        font-weight: bold;
        color: rgba(0, 0, 0, 0.6);
        margin-left: -70px;
      }
    }
  }

  .timeDivision {
    width: 300px;
    background: #ffffff;
    // margin-top: 55px;
    height: 72px;
    left: 872px;
    margin-left: 25px;
    border-radius: 16px;
  }
  .timePicker {
    width: 116% !important;
    height: 70px !important;
    border-radius: 15px !important;
  }
  .selectorDivision > div.timeDivision > div > div {
    width: 300px;
    height: 72px;
    box-shadow: inset 0px -2px 0px #c2c9d1;
    border-radius: 16px;
  }

  .slotsBeforeBreakDropdown {
    position: relative;
    // top: 20px;
    width: 300px;
    height: 72px;
    margin-left: 30px;
    background: #ffffff !important;
    box-shadow: inset 0px -2px 0px #c2c9d1;
    border-radius: 16px;
  }
  .slotsBeforeBreakDropdown > div > div > div > ul > li {
    // padding-top: 7px;
    // padding-bottom: 7px;
  }
  .selectedSlotsDropdownButton {
    background: #ffffff !important;
  }

  .slotsAfterBreakDropdown {
    position: relative;
    // top: -20px;
    width: 300px;
    height: 72px;
    margin-left: 46px;
    background: #ffffff !important;
    box-shadow: inset 0px -2px 0px #c2c9d1;
    border-radius: 16px;
  }

  .slotsAfterBreakDropdown > div > div > div > ul > li {
    // padding-top: 7px;
    // padding-bottom: 7px;
  }

  .startButton {
    position: relative;
    width: 132px;
    height: 56px;
    margin-left: 25px;
    margin-top: 50px;
  }

  .selectedSlotsAfterDropdownButton {
    background: white !important;
  }

  .slotPlanningDivision {
    position: relative;
    margin-top: 50px;
    width: 90%;
    left: 22px;
    height: 1px;
    background-color: #c2c9d1;
  }

  .dates {
    display: flex;
    overflow: auto;
    height: 100px;
    // margin-top: -70px;
  }

  .date {
    cursor: pointer;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    //width: 10%;
    margin: 15px;
    font-family: Montserrat;
    font-style: normal;
    // font-weight: 600;
    font-size: 16px;
    color: #3b4a57;
    white-space: pre-line;
    line-height: 22px;
    &.active {
      border-bottom: 2px solid #3b4a57;
      font-weight: 600;
      white-space: pre-line;
      line-height: 22px;
    }
  }

  .bEafLj .sc-1pnbgxk-6 {
    margin-left: 14px !important;
  }

  .preBookSlotNote {
    position: relative;
    margin-left: 3%;
    margin-top: 3%;
    font-family: Montserrat !important;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    color: #3b4a57;
  }

  .toggles {
    position: relative;
    margin-top: 2.5%;
    margin-left: 3%;
  }

  .preBookToggle {
  }

  .deleteToggle {
    margin-left: 20px;
  }

  .p-selectable-cell {
    padding: 0rem !important;
    border-radius: 15px !important;
    font-weight: bold;
    vertical-align: middle;
    color: $orange !important;
    max-width: 160px !important;
    text-align: center !important;
    justify-content: center !important;
    height: 50px;
    width: 80px;
  }

  .p-datatable-hoverable-rows .p-selectable-row :hover {
    cursor: pointer;
    // background-color: #e9ecef;
    background: #dddedf !important;
    color: black !important;
  }
  .p-datatable.p-datatable-hoverable-rows
    .p-datatable-tbody
    > tr:not(.p-highlight):not(.p-datatable-emptymessage):hover {
    background: white;
    // background: transparent;
    // background-color: #dddedf !important;
  }

  // .plannedSlots:hover {
  //   background-color: none !important;
  // }

  .checkBoxColumn {
    background: red !important;
    display: none;
  }

  .checkBoxContainer {
    padding-top: 12px;
    height: 50px;
    width: 80px;
    background: #f6f7f9 !important;
    box-shadow: 2px 3px 2px 2px #f6f7f9;
  }
  .checkBox {
    width: 60px;
    height: 18px;
  }
  .dockRow {
    // background-color: rgb(121, 49, 255);
    background-color: #f6f7f9;
    padding-top: 16px;
    box-shadow: 2px 3px 2px 2px #f6f7f9;
    color: black !important;
    font-weight: bold;
    border-radius: 15px;
    height: 50px;
    width: 80px;
    word-wrap: break-word;
  }

  .breakRow {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #d4e2f7 !important;
    color: black !important;
    vertical-align: middle !important;
    white-space: pre-wrap !important;
    box-shadow: 0px 15px 22px rgba(0, 0, 0, 0.06),
      0px 3px 8px rgba(0, 0, 0, 0.08), 0px 1px 1px rgba(0, 0, 0, 0.08),
      0px 3px 1px rgba(0, 0, 0, 0.04);
    font-weight: bold;
    padding-right: 3px;
    padding-left: 3px;
    width: 120px;
    height: 50px;
    border-radius: 15px;
  }

  .preBookRow {
    background-color: $orange;

    box-shadow: 0px 15px 22px rgba(0, 0, 0, 0.06),
      0px 3px 8px rgba(0, 0, 0, 0.08), 0px 1px 1px rgba(0, 0, 0, 0.08),
      0px 3px 1px rgba(0, 0, 0, 0.04);
    padding-top: 17px;
    color: white;
    font-weight: bold;
    border-radius: 15px;
    height: 50px;
    width: 80px;
  }
  .containerSlot {
    background-color: #581930;

    box-shadow: 0px 15px 22px rgba(0, 0, 0, 0.06),
      0px 3px 8px rgba(0, 0, 0, 0.08), 0px 1px 1px rgba(0, 0, 0, 0.08),
      0px 3px 1px rgba(0, 0, 0, 0.04);
    padding-top: 17px;
    color: white;
    font-weight: bold;
    border-radius: 15px;
    height: 50px;
    width: 80px;
  }

  .specialBookingSlot {
    background-color: $orange;
    //  #dc143c;
    // background-color: #0051c2;
    // background-color: purple;

    box-shadow: 0px 15px 22px rgba(0, 0, 0, 0.06),
      0px 3px 8px rgba(0, 0, 0, 0.08), 0px 1px 1px rgba(0, 0, 0, 0.08),
      0px 3px 1px rgba(0, 0, 0, 0.04);
    padding-top: 17px;
    color: white;
    font-weight: bold;
    border-radius: 15px;
    height: 50px;
    width: 80px;
  }

  .deletedRow {
    background-color: #e6ebf1;
    box-shadow: 0px 15px 22px rgba(0, 0, 0, 0.06),
      0px 3px 8px rgba(0, 0, 0, 0.08), 0px 1px 1px rgba(0, 0, 0, 0.08),
      0px 3px 1px rgba(0, 0, 0, 0.04);
    color: #c2c9d1;
    padding-top: 17px;
    vertical-align: middle !important;
    justify-content: center !important;
    font-weight: bold;
    border-radius: 15px;
    height: 50px;
    width: 80px;
  }

  .p-selectable-cell.p-highlight {
    // background-color: rgb(252, 61, 124) !important;
    // color: black !important;
  }

  .plannedSlots {
    display: flex;
    align-items: center;
    // margin-left: 2%;
    margin-top: 3%;
    vertical-align: middle !important;
    // justify-content: center;
    background-color: #f6f7f9 !important;
    // margin-top: 80px;
  }

  .dockColumns {
    // padding: 8px;
  }

  .p-datatable {
    border-collapse: inherit !important;
  }

  //hiding slots heading
  .p-datatable-thead {
    display: none;
  }

  //slots border spacing adjustment
  table {
    border-collapse: inherit !important;
    border-spacing: 18px;
  }

  .goLiveButton {
    position: relative;
    width: 162px;
    height: 56px;
    margin-top: 3%;
  }

  .prebook_slot_detail {
    width: 100%;
    height: 100%;
  }

  .p-selectable-cell.p-highlight {
    background: #dddedf !important;
    color: black !important;
    // background: #f2f3f5 !important;
  }

  // .p-datatable .p-datatable-tbody > tr > td.p-highlight {
  //   background: yellow !important;
  // }

  .plannedSlots .checkedRow {
    background-color: #dddedf !important;
    color: black !important;
  }

  // .warningImage {
  //   margin-right: 5px;
  // }

  //css for pre booking modal
  body > div.sc-m9cfk7-1.bHBLUE > div:nth-child(3) > div {
    /* max-height: calc(100vh - 4rem); */
    min-width: 20rem;
    max-width: 37rem;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    min-height: auto;
    min-height: initial;
    // height: auto;
    height: 85%;
    border-radius: 1.5rem;
    overflow: hidden;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}
.Toastify__toast-body > div:last-child {
  flex: 1 1;
  min-height: 1rem !important;
  font-family: "Montserrat" !important;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
}

.Toastify__toast--warning {
  background: #fff5e1;
  color: #3b4a57;
}
.Toastify__toast--error {
  color: #5c2952;
  background: #ffe7e7;
}

.Toastify__toast--success {
  background: #e1f4e5;
  color: #29545c;
}

.Toastify__toast--info {
  background: #e5eefa;
  color: #1e3264;
}

.Toastify__toast--default {
  background: #f4f2f0;
  box-shadow: 0px 0px 10px 5px #00000038 !important;
}

#nrtowacwa {
  min-width: 1rem !important;
}

.Toastify__toast {
  position: relative;
  min-height: 1rem !important;
  box-sizing: border-box;
  margin-bottom: 1rem;
  padding: 8px;
  border-radius: 4px;
  // box-shadow: 0 1px 10px 0 rgb(0 0 0 / 10%), 0 2px 15px 0 rgb(0 0 0 / 5%);
  box-shadow: none;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  font-family: var(--toastify-font-family);
  cursor: pointer;
  direction: ltr;
  height: -5rem !important;
}
.toastBody {
  // font-family: "Montserrat" !important;
  // color: #10171D;
  // font - size: 0.875 rem!important;
}
.toastProgress {
  // background: #333f48 !important;
}
//css for calendar
body
  > div.sc-m9cfk7-1.bHBLUE
  > div:nth-child(3)
  > div
  > div
  > div
  > div.sc-m9cfk7-4.lgrOsu
  > div
  > div
  > form
  > div:nth-child(3)
  > div.b_s_date_input
  > div
  > div
  > div
  > div
  > div
  > div.SingleDatePicker_picker.SingleDatePicker_picker_1.SingleDatePicker_picker__directionLeft.SingleDatePicker_picker__directionLeft_2
  > div
  > div
  > div
  > div.DayPicker_focusRegion.DayPicker_focusRegion_1 {
  margin-top: -300px;
  // margin-left: -20px;
}

.prebookModalDropdown {
  & + ul {
    max-height: 170px;
  }
}

.slotPlanningFrom, .slotPlanningTo{
  margin-left: 51px;
  width: 92%;
  margin-top: -25px;
}

 // Calender css
 .slotPlanningFrom, .slotPlanningTo {
  width: 90%;
  margin-bottom: 20px;
  .gqNyYv {
    min-height: unset;
    // height: 52px;
    box-sizing: border-box;
  }
  .hHEWwT {
    border-width: 2px;
  }
  table {
    left: 30px !important;
    width: 90% !important;
    height: 90% !important;
    tr {
      td {
        vertical-align: middle;
        height: 32px !important;
        width: 35px !important;
        &::after {
          width: 35px !important;
          height: 35px !important;
          margin-top: -2px !important;
          margin-left: -2.6px !important;
        }
        &.CalendarDay__today {
          &::after {
            // left: -2px !important;
            // top: -2px !important;
            // width: 30px !important;
          }
        }
      }
    }
  }
  .DayPicker_weekHeader_ul {
    .DayPicker_weekHeader_li {
      width: 49.3px !important;
    }
  }
  .bPDDWb {
    .DayPicker {
      width: 370px !important;
      & > div {
        & > div {
          width: 100% !important;
        }
      }
      .DayPicker_transitionContainer {
        width: 100% !important;
        height: 380px !important;
      }
    }
    .CalendarMonth_caption strong {
      margin-left: -30px !important;
    }
    .CalendarDay__today:not(.CalendarDay__selected_span):not(.CalendarDay__selected) {
      // width: 16% !important;
      // height: 33px !important;
    }
    .SingleDatePicker_picker {
      left: -65px !important;
    }
  }
}

.slotPlanningFrom .gqNyYv, slotPlanningTo .gqNyYv {
  min-height: unset;
  box-sizing: border-box;
}

.slot-planning-page .dateDivision, .slot-planning-page .dateDivisionToSection{
  text-align: left;
  }

  .dateDivisionToSection {
    margin-left: 50px;
    text-align: center;
    background: #ffffff;
    width: 295px;
    // margin-top: 55px;
   
    height: 72px;
    box-shadow: inset 0px -2px 0px #c2c9d1;
    border-radius: 16px;

    .dateDivisionContainer {
      // margin-left: -90px;
      .dateDivisionTitle {
        margin-left: -30px;
        font-size: 13px;
        color: rgba(0, 0, 0, 0.6);
      }
      .upcomingFromDate {
        margin-top: 10px;
        font-weight: bold;
        color: rgba(0, 0, 0, 0.6);
        margin-left: 72px;
      }
      .upcomingToDate {
        margin-top: 10px;
        font-weight: bold;
        color: rgba(0, 0, 0, 0.6);
        margin-left: -70px;
      }
    }
  }

  .upcomingFromDate .bPDDWb .CalendarDay__today:not(.CalendarDay__selected_span):not(.CalendarDay__selected),
  .upcomingToDate .bPDDWb .CalendarDay__today:not(.CalendarDay__selected_span):not(.CalendarDay__selected)
  {
    background: none !important;
    border: none !important;
    color: #C2C9D1 !important;
  }
