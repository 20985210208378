.qrCodeDetailsPage {
  background-color: white !important;
  width: 100% !important;
  height: 21% !important;
  /* overflow: scroll; */
}

.card_box_qr_code {
  height: 500px;
  display: flex;
  margin-top: 200px;
  border-radius: 8px;
  width: 1050px !important;
  justify-content: center; /* horizontally center */
  align-items: center; /* vertically center */
  box-shadow: 3px 5px 10px #bbbbbb !important;
  margin-bottom: 50px;
}

.qrCode_page_container {
  max-width: 1050px;
  margin: 0px auto;
  width: 100%;
}
.cards_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.cardTitleQRCode {
  /* border: 2px solid black; */
  font-size: 1.8em !important;
  font-weight: bold !important;
  margin-bottom: 20px !important;
  margin-left: 300px;
  justify-content: center; /* horizontally center */
  align-items: center; /* vertically center */
  font-family: MONTSERRAT !important;
}

.cardContent {
  margin-top: 30px;
  margin-left: 370px;
  text-align: center;
  font-size: 1em !important;
  width: 300px;
  color: black;
  font-family: MONTSERRAT !important;
}

.dockClass {
  margin-top: 40px;
  margin-left: 370px;
  text-align: center;
  font-weight: bold !important;
  font-size: 2em !important;
  width: 300px;
  color: green;
  font-family: MONTSERRAT !important;
}

.outsideCardContent {
  /* border: 2px solid black; */
  font-size: 1.5em !important;
  font-weight: bold !important;
  margin-bottom: 20px !important;
  width: 80% !important;
  margin-top: 40px;
  margin-left: 15%;
  text-align: center;
  font-family: MONTSERRAT !important;
}

.greenTickImg {
  display: flex;
  justify-content: center; /* horizontally center */
  align-items: center; /* vertically center */
  margin-bottom: 15px;
  margin-top: -100px;
  width: 1050px;
}

.confirmButton {
  margin-top: 30px;
  background-color: black;
  justify-content: center; /* horizontally center */
  align-items: center; /* vertically center */
  margin-left: 450px;
}

.contentDivision {
  justify-content: center; /* horizontally center */
  align-items: center; /* vertically center */
}
