// Dashboard page design
.dashboard-page {
  width: 100%;
  height: 100%;
  .dashboard-container {
    max-width: 80%;
    margin: 0px auto;
    width: 100%;
    // min-height: 960px;
    min-height: 70vh;
    margin-bottom: 10%;
  }
}

.no_data_text {
  color: #787878;
  font-size: 14px;
}

// Loader styling
.loading_container {
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loader_page {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.219);
}

.authPageAditroSms {
  // padding-left: 50%;
  // margin: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

// No internet
.outer_box {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.no_internet_connection {
  flex-direction: column;
  .nops_logo {
    padding: 15px;
    img {
      max-width: 120px;
    }
  }
  h4 {
    font-size: 2.3rem;
    color: $orange;
    margin: 15px 0px;
    font-weight: 700;
  }
  p {
    font-size: 1rem;
    color: #676776;
  }
}

// Button
Button:active,
Button:focus,
Button.active {
  background-image: none;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
Button {
  text-transform: none !important;
}
Button:focus {
  border: transparent !important;
}
.cust_detail_box {
  width: 100%;
  display: flex;
  flex-direction: column;
  .sup_act_btn {
    font-size: 0.8rem;
    font-family: inherit;
    font-weight: 600;
    background-color: $orange;
    color: #fff;
    margin: 10px 0px;
    border-radius: 20px;
    padding: 10px;
    text-align: center;
    cursor: pointer;
    text-decoration: none;
    &:hover {
      background-color: $orange_hover;
      color: #fff;
    }
  }
}

// 404 page
.page_404 {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f4f4f4;
  flex-direction: column;
  .tt {
    font-size: 5rem;
    font-weight: 700;
    color: #666;
    margin-bottom: 10px;
  }
  .not_found {
    font-size: 2rem;
    font-weight: 700;
    color: #9999;
  }
  p {
    margin-top: 30px;
  }
}

// aditro login button in appjs
.azur_login_btn {
  width: 100%;
  padding: 12px;
  background: #31b0d5;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-family: inherit;
  font-size: 1rem;
  cursor: pointer;
  margin-top: -10px;
}
.login_message {
  font-size: 14px;
  color: #666;
  margin-top: 30px;
  margin-bottom: 10px;
  font-weight: 500;
}
.login_page_logo {
  max-width: 120px;
  object-fit: contain;
  position: absolute;
  top: 15px;
  left: 15px;
  z-index: 1;
}
.home_btn {
  font-size: 1rem;
  text-decoration: none;
  font-weight: 500;
  color: #fff;
  background: $orange;
  padding: 10px;
  border-radius: 5px;
  margin-top: 20px;
}
