.qrCodeScannerPage {
  background-color: white !important;
  width: 100% !important;
  // height: 21% !important;
  justify-content: center; /* horizontally center */
  /* overflow: scroll; */
}

.card_box_qr_codes {
  height: 500px;
  display: flex;
  margin-top: 100px;
  border-radius: 8px;
  width: 1050px !important;
  box-shadow: 3px 5px 10px #bbbbbb !important;
  justify-content: center; /* horizontally center */
}

.inputTextDivs {
  justify-content: center; /* horizontally center */
  align-items: center; /* vertically center */
}
.inputTexts {
  justify-content: center; /* horizontally center */
  width: 400px;
  margin-left: 420px;
}
.qrCode_page_containers {
  max-width: 1050px;
  margin: 0px auto;
  justify-content: center; /* horizontally center */
  width: 100%;
  // height: 650px;
  margin-bottom: 10%;
  min-height: 65vh;
}
.cards_containers {
  // display: flex;
  // flex-wrap: wrap;
  justify-content: center; /* horizontally center */
}

.cardTitleQRCodes {
  /* border: 2px solid black; */
  color: black;
  font-size: 1.8em !important;
  font-weight: bold !important;
  margin-bottom: 20px !important;
  width: 880px;
  margin-left: 320px;
  margin-top: 50px;
  justify-content: center; /* horizontally center */
  font-family: MONTSERRAT !important;
}

.outsideCardContents {
  /* border: 2px solid black; */
  font-size: 1.5em !important;
  font-weight: bold !important;
  margin-bottom: 20px !important;
  // width: 80% !important;
  // margin-left: 15%;
  margin-top: 40px;
  text-align: center;
  font-family: MONTSERRAT !important;
}

.qrCodeImgs {
  display: flex;
  margin-top: 30px;
  justify-content: center; /* horizontally center */
  align-items: center; /* vertically center */
  margin-left: 120px;
}
.downArrowImgs {
  justify-content: center; /* horizontally center */
  align-items: center; /* vertically center */
  // margin-left: 500px;
}
.contentDivisions {
  justify-content: center; /* horizontally center */
}
