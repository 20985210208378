.boxAnimationCustomer {
  margin-left: -20px;
  margin-right: -20px;
  margin-top: 50px;
}
.boxCustomer {
  margin-left: -25px;
  margin-right: -25px;
  margin-top: 50px;
}
.scroll-tableCustomer {
  overflow-x: scroll;
  padding-bottom: 50px;
}
.settingButtonCustomer {
  height: 25px;
  width: 25px;
  margin-right: 0px;
}
.insideButtonCustomer {
  height: 25px;
  width: 25px;
  margin-right: 6%;
}
.insideButtonCancelCustomer {
  height: 15px;
  width: 15px;
  margin-right: 6%;
}
.insideButtonDelayCustomer {
  height: 15px;
  width: 15px;
  margin-right: 6%;
  margin-left: -15px;
}
.delayAndCancelCustomer {
  margin-top: 30px;
  margin-bottom: -500px;
}
.p-datatable-emptymessageCustomer {
  td {
    text-align: center !important;
  }
}
.license_NumberCustomer {
  & > div {
    &:first-child {
      min-height: 68px !important;
      box-sizing: border-box;
      height: 52px;
      border-width: 2px;
      background: #f6f7f9;
      box-shadow: 0 2px 0 #c2c9d1;
    }
  }
}
.date_InputCustomer {
  td {
    vertical-align: middle;
    height: 40px !important;
    width: 48px !important;
    &::after {
      width: 48px !important;
      height: 40px !important;
    }
    &.CalendarDay__today {
      &::after {
        left: -2px !important;
        top: -2px !important;
      }
    }
  }
}
