.sub_menu_customer {
  margin-top: 20px;
  .sub_menu_links_customer {
    font-weight: 500;
    border: none;
    outline: none;
    padding-bottom: 15px;
    margin-right: 25px;
    font-family: inherit;
    background: transparent;
    cursor: pointer;
    font-size: 15px;
    color: #4a051e;
    &.active {
      border-bottom: 2.8px solid $orange !important;

    }
  }
}
.in_active {
  //  font-weight: 700;
  color: red;
}
.active {
  // font-weight: 700;
  color: green;
}
.customer_profile_input_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;

  .customer_profile_input_control {
    width: 48%;
    margin-bottom: 20px;
    & > div {
      &:first-child {
        min-height: unset !important;
        box-sizing: border-box;
        height: 67px;
        border-width: 2px;
        background: #f6f7f9;
        box-shadow: 0 2px 0 #c2c9d1;
      }
    }
  }
  .customer_profile_dropdown_container {
    width: 48%;
    margin-bottom: 20px;
    .customer_profile_dropdown {
      border-width: 2px;
      outline: none;
      padding: 0px;
      height: 67px;
      margin: 0px;
    }
    .customer_profile_dropdown_item {
      padding: 10px 20px;
      span {
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
}