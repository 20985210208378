.slot-booking-page {
  width: 100%;
}
.sub_links {
  margin: 20px 0px;
  display: flex;
  align-items: center;
  span {
    color: #4a051e;
    font-size: 14px;
    font-weight: 600;
  }
  .seperator {
    margin: 0px 10px;
    color: black;
    font-size: 14px;
  }
  a {
    text-decoration: none;
    span {
      color: $orange;
    }
  }
}
.book_slot_title {
  font-size: 30px;
  font-weight: 700;
  color: black;
}
.eErOeE:first-child {
  padding-left: 0px !important;
}
.slot_row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  .slot_colom_left {
    width: 43%;
  }
  .slot_colom_right {
    width: 47%;
  }
}
.facility_dropdown {
  margin: 25px 0px;
  .facility_dropdown_btn {
    border: none;
    outline: none;
    padding: 2rem 1.5rem;
    margin: 0px;
  }
}
.weeks_radio {
  flex-direction: row !important;
  margin-bottom: 20px;
}
.radio-1 {
  width: 50%;
}
.slot_groups {
  p {
    font-size: 14px;
    font-weight: 600;
    padding-left: 0.3rem;
    padding-right: 0.3rem;
  }
}
.info_para {
  font-size: 12px;
  color: #3b4a57;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  svg {
    margin-right: 5px;
    font-size: 16px;
  }
}
.book_slot_detail {
  width: 100%;
  height: 100%;
  padding-left: 25px;
  border-left: 1px solid #e6ebf1;
}

// Right part
.detail_row {
  display: flex;
  width: 100%;
  justify-content: space-between;
  label {
    font-size: 12px;
    font-weight: 600;
  }
  .b_s_input_control {
    width: 48%;
    margin-bottom: 20px;
    & > div {
      &:first-child {
        min-height: unset !important;
        box-sizing: border-box;
        height: 52px;
        border-width: 2px;
        background: #f6f7f9;
        box-shadow: 0 2px 0 #c2c9d1;
      }
    }
  }
  .b_s_dropdown_container {
    width: 48%;
    .b_s_dropdown {
      border-width: 2px;
      outline: none;
      padding: 0px;
      height: 52px;
      margin: 0px;
    }
    .b_s_dropdown_item {
      padding: 10px 20px;
      span {
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
}

.slot_detail_submit_btn {
  min-width: 200px;
  & > span {
    padding: 12px 20px;
    justify-content: center;
  }
}

.b_s_date_input {
  width: 48%;
  margin-bottom: 20px;
  .gqNyYv {
    min-height: unset;
    height: 52px;
    box-sizing: border-box;
  }
  .hHEWwT {
    border-width: 2px;
  }
  table {
    tr {
      td {
        vertical-align: middle;
        height: 35px !important;
        width: 35px !important;
        &::after {
          width: 35px !important;
          height: 35px !important;
        }
        &.CalendarDay__today {
          &::after {
            left: -2px !important;
            top: -2px !important;
          }
        }
      }
    }
  }
  .DayPicker_weekHeader_ul {
    .DayPicker_weekHeader_li {
      width: 35px !important;
    }
  }
  .bPDDWb {
    .DayPicker {
      width: 265px !important;
      & > div {
        & > div {
          width: 100% !important;
        }
      }
      .DayPicker_transitionContainer {
        width: 100% !important;
      }
    }
  }
}

.slot_item_outer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.25rem;
  min-height: 2.75rem;
  min-width: 4rem;
  .slot_item {
    background: #fff;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0px 5px 4px 0px #0000004d;
    border: 0 solid;
    color: $orange;
    border: none;
    outline: none;
    font-family: inherit;
    font-size: 1.1rem;
    font-weight: 600;
    width: 100%;
    text-align: center;
    cursor: pointer;
    transition: 0.4s;
    &:hover {
      background-color: rgb(246, 247, 249);
    }
    &.selected {
      background-color: $orange;
      color: #fff;
    }
  }
}
.eErOeE:last-child {
  padding-right: 0px;
}
//css for calendar component
#root
  > div
  > amplify-authenticator
  > div.dashboard-page
  > div.dashboard-container
  > div
  > div
  > div.slot_colom_right
  > div
  > form
  > div:nth-child(4)
  > div.b_s_date_input
  > div
  > div
  > div
  > div
  > div
  > div.SingleDatePicker_picker.SingleDatePicker_picker_1.SingleDatePicker_picker__directionLeft.SingleDatePicker_picker__directionLeft_2
  > div
  > div
  > div
  > div.DayPicker_focusRegion.DayPicker_focusRegion_1
  > div.DayPicker_transitionContainer.DayPicker_transitionContainer_1.DayPicker_transitionContainer__horizontal.DayPicker_transitionContainer__horizontal_2 {
  margin-top: -300px;
}

.upcomingWeeks{
  margin-bottom: 30px;
  margin-top: -20px;
}
