//theming-CSS

.AzaeO {
  background-color: $orange_hover !important;
  &:hover {
    background-color: $orange !important;
  }
  &:before {
    background-color: $orange !important;
  }
}

.AzaeO:hover:before {
  background-color: $orange_hover !important;
}

// .css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked {
//   color: $orange !important;
// }

.currentweek_radio {
  color: $orange !important;
}

.bihiTu {
  border: 0.1875rem solid $orange !important;
}

.hHEWwT {
  border: 0.1875rem solid $orange !important;
}

.p-button {
  background-color: $orange !important;
  border-color: $orange !important;
  &.p-button-outlined {
    background-color: transparent !important;
    color: $orange !important;
  }
}

.p-inputtext {
  //   &:enabled {
  //     border-color: $orange !important;
  //   }
  &:enabled:hover {
    border-color: $orange !important;
  }
  &:focus {
    box-shadow: 0 0 0 0.2rem #fdeee9 !important;
  }
}

.p-dropdown:enabled {
  border-color: $orange !important;
}
.p-dropdown:not(.p-disabled):hover {
  border-color: $orange !important;
}

.jEngZp {
  &:focus {
    color: $orange !important;
  }
  &:hover {
    color: $orange !important;
  }
  svg {
    path {
      fill: $orange !important;
    }
  }
}

.CalendarDay__selected:active {
  background-color: $orange !important;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  &.p-highlight {
    color: $orange !important;
  }
  &:not(.p-highlight):not(.p-disabled):hover {
    color: $orange !important;
    background: #eef2ff !important;
  }
}

.CalendarDay__default.CalendarDay__selected:after {
  background-color: $orange !important;
}

.dropdownMenuItem {
  &:focus {
    color: $orange !important;
  }
  &:hover {
    color: $orange !important;
  }
  svg {
    path {
      fill: $orange;
    }
  }
}

.jKyqIN {
  background-color: $orange !important;
  border-color: $orange !important;
}
.fZEKNo {
  border-color: $orange !important;

  svg {
    path {
      fill: $orange !important;
    }
  }
}

.p-datatable {
  .p-datatable-thead {
    tr {
      th {
        color: black;
        background-color: $white_grey;
      }
    }
  }
  .p-sortable-column.p-highlight {
    color: $orange;
    background-color: #eaedf1;
    .p-sortable-column-icon {
      color: $orange;
    }
  }
  .p-sortable-column.p-highlight:not(.p-sortable-disabled):hover {
    color: $orange;
    background-color: #eaedf1;
    .p-sortable-column-icon {
      color: $orange;
    }
  }
  .p-sortable-column:not(.p-highlight):not(.p-sortable-disabled):hover {
    color: $orange;
    background-color: #eaedf1;
    .p-sortable-column-icon {
      color: $orange;
    }
  }
}

.p-column-filter-menu-button.p-column-filter-menu-button-open,
.p-column-filter-menu-button.p-column-filter-menu-button-open:hover {
  color: $orange;
  background-color: #eaedf1;
}

.p-column-filter-menu-button:focus {
  box-shadow: 0 0 0 0.2rem #e06d46;
}

.p-column-filter-menu-button.p-column-filter-menu-button-active,
.p-column-filter-menu-button.p-column-filter-menu-button-active:hover {
  color: $orange;
  background-color: #eaedf1;
}

.p-column-filter-menu-button:hover {
  color: $orange;
  background-color: #eaedf1;
}

.jEngZp:focus, .jEngZp:hover {
  background-color: #E6EBF1;
  color: #0051C2;
  outline: 0;
}

#drpGroup .jEngZp:focus, #drpGroup .jEngZp:hover {
  background-color: #fff;
}