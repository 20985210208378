.main_header {
  width: 100%;
  background-color: $white_grey;
  overflow: visible;
  padding: 10px 25px;
  display: flex;
  align-items: center;
  .left_header {
    width: 50%;
    display: flex;
    align-items: center;
    .logo_box {
      max-width: 100px;
      display: inline-block;
      img {
        max-width: 100%;
      }
    }
    .verticle_bar {
      height: 40px;
      width: 1px;
      background-color: gray;
      margin: 0px 10px;
    }
    .support_btn {
      color: #4a051e;
      font-family: inherit;
      background-color: transparent;
      font-style: 14px;
      border-radius: 52px;
    }
  }
  .right_header {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .time {
      display: flex;
      align-items: center;
      margin-right: 30px;
      color: #e84f1e;
    }
    .date {
      display: flex;
      align-items: center;
      margin-right: 30px;
      color: #e84f1e;
    }
    .language_selector {
      display: flex;
      align-items: center;
      margin-right: 30px;
      .language_badge {
        display: inline-block;
        padding: 3px;
        background: $orange;
        color: $white_grey;
        font-weight: 600;
        font-size: 14px;
      }
      .language_dropdown {
        color: $orange;
        background-color: transparent !important;
        font-size: 15px !important;
        outline: none !important;
        background-image: none !important;
        outline: 0 !important;
        box-shadow: none !important;
      }
    }
    .user_btn {
      font-size: 13px !important;
      //   border-radius: 59px !important;
      font-family: inherit;
      color: $orange !important;
      background-color: white !important;
      font-weight: 700;
      // text-transform: capitalize !important;
    }
  }
}

.dashboard_header {
  width: 100%;
  background-color: $white_grey;
  .sub_menu {
    .sub_menu_links {
      font-family: inherit;
      color: #4a051e;
      border: transparent;
      cursor: pointer;
      background: transparent;
      font-size: 15px;
      font-weight: 500;
      padding: 0px;
      padding-top: 5px;
      padding-bottom: 10px;
      margin-left: 25px;
      transition: 0.4s;
      box-sizing: border-box;
      &.active {
        border-bottom: 2.8px solid $orange;
      }
    }
  }
}
