.landingPage {
  background-color: white !important;
  width: 100% !important;
  // height: 21% !important;
  /* overflow: scroll; */
}

.card_box {
  height: 320px;
  margin-top: 2%;
  border-radius: 8px;
  width: 48% !important;
  box-shadow: 3px 5px 10px #bbbbbb !important;
}

.card_box_qr_code {
  height: 345px;
  margin-top: 50px;
  border-radius: 8px;
  width: 90% !important;
  box-shadow: 3px 5px 10px #bbbbbb !important;
}

.title {
  font-size: 25px;
  color: black;
  /* margin-left: 12%; */
  margin-top: 3%;
  font-weight: bold;
  font-family: MONTSERRAT !important;
}

.arrow {
  margin-top: -4%;
  margin-left: 3%;
  font-size: 35px;
  width: 30px;
}

.bookingButton {
  margin-left: 65% !important;
  margin-top: 3%;
  width: 10%;
  background-color: white !important;
  color: black !important;
  border-color: white !important;
}

.landing_page_container {
  max-width: 1050px;
  margin: 0px auto;
  width: 100%;
  margin-bottom: 10%;
  min-height: 72vh;
}
.landing_page_container_qr_code {
  max-width: 1050px;
  margin: 0px auto;
  position: relative;
  width: 100%;
  margin-top: -150px;
  height: 650px;
}
.cards_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.cardTitle {
  /* border: 2px solid black; */
  color: black;
  font-size: 1.5em !important;
  font-weight: bold !important;
  margin-bottom: 0.5 em !important;
  width: 80% !important;
  font-family: MONTSERRAT !important;
  margin-left: 14% !important;
  margin-top: -9% !important;
}
.cardTitle1QRCode {
  /* border: 2px solid black; */
  color: black;
  font-size: 1em !important;
  font-weight: bold !important;
  margin-bottom: 15px !important;
  width: 80% !important;
  margin-top: -60px;
  font-family: MONTSERRAT !important;
}
.cardTitle2QRCode {
  /* border: 2px solid black; */
  color: black;
  font-size: 1.5em !important;
  font-weight: bold !important;
  margin-bottom: 20px !important;
  width: 80% !important;
  font-family: MONTSERRAT !important;
}
.mainTitle {
  width: 70%;
}

.contentDivision {
  padding: 5px;
  margin-top: -3%;
  height: 170px;
  width: 100%;
  /* border:2px solid black; */
}

.buttonDivision {
  margin-left: 72%;
  margin-top: -58%;
  height: 40px;
  width: 30%;
  /* border:2px solid black; */
}

.cardTitle {
  margin-top: -2%;
  margin-left: 2%;
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 0.5em;
  font-size: 25px !important;
}

.calendarImage {
  margin-top: 3%;
  padding: 5px !important;
  width: 10% !important;
}

.vanCargoImage {
  margin-top: 3%;
  padding: 5px !important;
  width: 10% !important;
}

.folderImage {
  margin-top: 3%;
  padding: 5px !important;
  width: 10% !important;
}

.searchImage {
  margin-top: 3%;
  padding: 5px !important;
  width: 10% !important;
}

.slotPlanningImage {
  margin-top: 3%;
  padding: 5px !important;
  width: 10% !important;
}
.BookASlotButton {
  margin-top: 4%;
  height: 33%;
  width: 45%;
  font-family: MONTSERRAT;
}

.carrierRegistrationButton {
  margin-top: 4%;
  height: 33%;
  width: 64%;
  font-family: MONTSERRAT;
}

.manageMasterDataButton {
  margin-top: 4%;

  height: 33%;
  width: 63%;
  font-family: MONTSERRAT;
}

.viewBookingsButton {
  justify-content: center;
  margin-left: 0%;
  margin-top: 4%;
  height: 50px;
  width: 240px;

  // margin-top: 4%;
  // height: 33%;
  // width: 52%;
  font-family: MONTSERRAT;
}

.carrierDataButton {
  margin-top: 4%;
  height: 33%;
  width: 30%;
  font-family: MONTSERRAT;
}
.ScanAQRCodeButton {
  margin-top: 4%;
  font-family: MONTSERRAT;
}
.content {
  font-family: MONTSERRAT !important;
  line-height: 24px !important;
  font-size: 16px;
}

.no-role {
  font-size: 2rem;
  color: #0000006e;
  max-width: 80%;
  text-align: center;
  margin: 100px auto;
  line-height: 1.3;
}
