// Theme Colors
$white_grey: #edeae6;
$orange: #e84f1e;
$orange_hover: #b6350b;

// Amplify theme color
:root {
  --amplify-primary-color: #e84f1e;
  --amplify-primary-tint: #b6350b;
  --amplify-primary-shade: #b6350b;
  --amplify-font-family: inherit;
  --amplify-secondary-color: #54171d;
  --amplify-text-md-sub: 1.35rem;
}
.form-section-header .header {
  margin-top: -20px;
}

@import "./module/reset.scss";
@import "./module/theme.scss";
@import "./module/common.scss";
@import "./module/main-header.scss";
@import "./module/landing-page.scss";
@import "./module/footer.scss";
@import "./module/slot-booking.scss";
@import "./module/slot-planning.scss";
@import "./module/slot-replanning.scss";
@import "./module/view-booking.scss";
@import "./module/qrCodeScanner.scss";
@import "./module/qrCodeDetails.scss";
@import "./module/view-booking-customer.scss";
@import "./module/replanning-prebook-details.scss";
@import "./module/master-data.scss";
@import "./module/carrier-data.scss";
@import "./module/facility-data.scss";
@import "./module/dock-data.scss";
@import "./module/customer-data.scss";
@import "./module/shifting-slots.scss";
@import "./module/rolling.scss";
