// Breakpoints
$bp-small: 48em; // 768px
$bp-medium: 64em; // 1024px
$bp-large: 85.375em; // 1366px
$bp-xlarge: 120em; // 1920px
$bp-xxlarge: 160em; // 2560px

.dashboard-page {
  &.rolling_black {
    background-color: black;
    .p-datatable .p-datatable-tbody > tr {
      background: black;
      // color: #495057;
      color: white;
      transition: box-shadow 0.2s;
      outline-color: #c1c2f9;
    }
    .p-datatable .p-datatable-thead tr th {
      color: white;
      background-color: black;
    }
    .p-datatable .p-paginator-bottom {
      border-width: 0 0 1px 0;
      /* border-radius: 0; */
      background-color: black;
    }

    .p-component,
    .p-component * {
      box-sizing: border-box;
      background-color: black;
    }
    .p-component * {
      box-sizing: border-box;
      background-color: black;
    }
  }
  .rollingDashboard-container {
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    margin: 0px auto;
    min-height: 100vh;

    .rolling_title_container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin: 2rem 0px;
      .rolling_screen_title {
        font-size: 1.2rem;
        font-weight: 700;
        color: white;
      }
      .arrv {
        display: flex;
        align-items: center;
        .arriv_text {
          font-size: 2rem;
          font-weight: 700;
          margin: 0px;
        }
      }

      progress {
        border: none;
        width: 300px;
        height: 10px;
        color: #f4f4f4;
      }

      progress::-moz-progress-bar {
        background: #0f0f0f;
        border-radius: 19px;
      }
      progress::-webkit-progress-bar {
        background: #f4f4f4;
        border-radius: 10px;
      }

      progress::-webkit-progress-value {
        background: rgb(224, 102, 31);
        border-radius: 10px;
      }
    }

    @media screen and (min-width: $bp-medium) {
      .rolling_table {
        font-size: 1.2rem;
      }
      .rolling_paginator.p-paginator .p-paginator-pages .p-paginator-page {
        min-width: 3rem;
        height: 3rem;
        margin: 0.143rem;
        font-size: 1.2rem;
      }
      .arriv_text {
        font-size: 2rem;
        font-weight: 700;
        margin: 0px;
      }
    }

    @media screen and (min-width: $bp-large) {
      .rolling_table {
        font-size: 1.4rem;
      }
      .rolling_paginator.p-paginator .p-paginator-pages .p-paginator-page {
        min-width: 4rem;
        height: 4rem;
        margin: 0.145rem;
        font-size: 1.5rem;
      }
      .arriv_text {
        font-size: 3rem;
        font-weight: 700;
        margin: 0px;
      }
    }
    @media screen and (min-width: $bp-xlarge) {
      .rolling_table {
        font-size: 1.6rem;
      }
      .rolling_paginator.p-paginator .p-paginator-pages .p-paginator-page {
        min-width: 5rem;
        height: 5rem;
        margin: 0.147rem;
        font-size: 1.7rem;
      }
      .arriv_text {
        font-size: 4rem;
        font-weight: 700;
        margin: 0px;
      }
    }
    @media screen and (min-width: $bp-xxlarge) {
      .rolling_table {
        font-size: 1.8rem;
      }
      .rolling_paginator.p-paginator .p-paginator-pages .p-paginator-page {
        min-width: 6rem;
        height: 6rem;
        margin: 0.149rem;
        font-size: 1.9rem;
      }
      .arriv_text {
        font-size: 5rem;
        font-weight: 700;
        margin: 0px;
      }
    }
  }
}
.rolling_facility_dropdown {
  & + ul {
    max-height: 127px;
  }
}
.select_facility_modal {
  min-width: 400px !important;
  h4 {
    font-weight: 700;
    font-size: 1.3rem;
    margin: 0.5rem 0px;
  }
}

.rolling_booked {
  color: white;
}
