.manage_master_data_title {
  font-size: 30px;
  font-weight: 700;
  color: #3b4a57;
}

// .sub_menu_master_data {
//   .sub_menu_links_master_data {
//     font-family: inherit;
//     color: #4a051e;
//     border: none;
//     cursor: pointer;
//     background: transparent;
//     font-size: 15px;
//     font-weight: 500;
//     padding: 0px;
//     padding-top: 5px;
//     padding-bottom: 10px;
//     margin-right: 25px;
//     margin-top: 10px;
//     box-sizing: border-box;
//     &.active {
//       border-bottom: 2.8px solid $orange;
//     }
//   }
// }
.sub_menu_master_data {
  margin-top: 20px;
  .sub_menu_links_master_data {
    font-weight: 500;
    border: none;
    outline: none;
    padding-bottom: 15px;
    margin-right: 25px;
    font-family: inherit;
    background: transparent;
    cursor: pointer;
    font-size: 15px;
    color: #4a051e;
    &.active {
      border-bottom: 2.8px solid $orange !important;
    }
  }
}

.driver_input_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;

  .driver_input_control {
    width: 48%;
    margin-bottom: 20px;
  }

  .driver_disable_input_control {
    width: 48%;
    margin-bottom: 20px;
    & > div {
      &:first-child {
        min-height: unset !important;
        box-sizing: border-box;
        height: 67px;
        border-width: 2px;
        // background: #f6f7f9;
        background: lightgrey;
        // box-shadow: 0 2px 0 #c2c9d1;
      }
    }
    // & label {
    //   color: red !important;
    //   font: bold;
    // }
  }

  .m_d_dropdown_container {
    width: 48%;
    margin-bottom: 20px;
    .m_d_dropdown {
      border-width: 2px;
      outline: none;
      padding: 0px;
      height: 67px;
      margin: 0px;
      & + ul {
        height: 320px;
      }
    }
    .m_d_driver_status_dropdown {
      border-width: 2px;
      outline: none;
      padding: 0px;
      height: 67px;
      margin: 0px;
    }
    .m_d_dropdown_item {
      padding: 10px 20px;
      span {
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
  .m_d_carrier_dropdown_container {
    width: 48%;
    margin-bottom: 20px;
    .m_d_carrier_dropdown {
      border-width: 2px;
      outline: none;
      padding: 0px;
      height: 67px;
      margin: 0px;
    }
    .m_d_carrier_dropdown_item {
      padding: 10px 20px;
      span {
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
  .driver_input_container .m_d_carrier_dropdown_container.drpGroup .m_d_carrier_dropdown_item:nth-child(1){
    margin-top: -22px;
  }
}

.carrier_input_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;

  .carrier_input_control {
    width: 48%;
    margin-bottom: 20px;
  }

  .carrier_disable_input_control {
    width: 48%;
    margin-bottom: 20px;
    & > div {
      &:first-child {
        min-height: unset !important;
        box-sizing: border-box;
        height: 67px;
        border-width: 2px;
        // background: #f6f7f9;
        background: lightgrey;
        // box-shadow: 0 2px 0 #c2c9d1;
      }
    }
    // & label {
    //   color: red !important;
    //   font: bold;
    // }
  }

  .m_d_carrier_dropdown_container {
    width: 48%;
    margin-bottom: 20px;
    .m_d_carrier_dropdown {
      border-width: 2px;
      outline: none;
      padding: 0px;
      height: 67px;
      margin: 0px;
    }
    .m_d_carrier_dropdown_item {
      padding: 10px 20px;
      span {
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
}

.customer_input_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;

  .customer_input_control {
    width: 48%;
    margin-bottom: 20px;
  }
  .customer_disable_input_control {
    width: 48%;
    margin-bottom: 20px;
    & > div {
      &:first-child {
        min-height: unset !important;
        box-sizing: border-box;
        height: 67px;
        border-width: 2px;
        // background: #f6f7f9;
        background: lightgrey;
        // box-shadow: 0 2px 0 #c2c9d1;
      }
    }
    // & label {
    //   color: red !important;
    //   font: bold;
    // }
  }
  .m_d_customer_dropdown_container {
    width: 48%;
    margin-bottom: 20px;
    .m_d_customer_dropdown {
      border-width: 2px;
      outline: none;
      padding: 0px;
      height: 67px;
      margin: 0px;
    }
    .m_d_customer_dropdown_item {
      padding: 10px 20px;
      span {
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
  .m_d_carrier_dropdown_container {
    width: 48%;
    margin-bottom: 20px;
    .m_d_carrier_dropdown {
      border-width: 2px;
      outline: none;
      padding: 0px;
      height: 67px;
      margin: 0px;
    }
    .m_d_carrier_dropdown_item {
      padding: 10px 20px;
      span {
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
  .customer_input_container .m_d_carrier_dropdown_container.drpGroup .m_d_carrier_dropdown_item:nth-child(1){
    margin-top: -22px;
  }
}

.dock_input_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;

  .dock_input_control {
    width: 48%;
    margin-bottom: 20px;
  }
  .dock_disable_input_control {
    width: 48%;
    margin-bottom: 20px;
    & > div {
      &:first-child {
        min-height: unset !important;
        box-sizing: border-box;
        height: 67px;
        border-width: 2px;
        // background: #f6f7f9;
        background: lightgrey;
        // box-shadow: 0 2px 0 #c2c9d1;
      }
    }
    // & label {
    //   color: red !important;
    //   font: bold;
    // }
  }
  .m_d_dock_dropdown_container {
    width: 48%;
    margin-bottom: 20px;
    .m_d_dock_dropdown {
      border-width: 2px;
      outline: none;
      padding: 0px;
      height: 67px;
      margin: 0px;
    }
    .m_d_dock_dropdown_item {
      padding: 10px 20px;
      span {
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
}

.facility_input_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;

  .facility_input_control {
    width: 48%;
    margin-bottom: 20px;
  }
  .m_d_facility_dropdown_container {
    width: 48%;
    margin-bottom: 20px;
    .m_d_facility_dropdown {
      border-width: 2px;
      outline: none;
      padding: 0px;
      height: 67px;
      margin: 0px;
    }
    .m_d_facility_dropdown_item {
      padding: 10px 20px;
      span {
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
}

.masterDataAddButton {
  margin-top: 4%;
  height: 30%;
  width: 24%;
  font-family: inherit;
  span {
    display: flex !important;
    align-items: center !important;
  }
}
.add_icon {
  font-weight: 700;
  font-size: 30px !important;
  margin-left: 10px;
}

.masterDataDockAddButton {
  margin-top: 4%;
  height: 30%;
  width: 45%;
  font-family: inherit;
  span {
    display: flex !important;
    align-items: center !important;
  }
}
.add_icon {
  font-weight: 700;
  font-size: 30px !important;
  margin-left: 10px;
}
.masterDataDropdown {
  & + ul {
    max-height: 250px;
  }
}

// .p-multiselect-panel{
// z-index: 10001 !important;
// }

.p-checkbox .p-checkbox-box.p-highlight {
  border-color: #e84f1e;
  background: #e84f1e;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  background: #e84f1e;
}

.p-datatable .p-datatable-tbody > tr.p-highlight {
  color: #e84f1e;
  outline-color: #efa95e;
}

.p-datatable.p-datatable-striped .p-datatable-tbody > tr.p-row-odd.p-highlight{
  color: #e84f1e;
}

.p-checkbox .p-checkbox-box{
  border: 2px solid #e84f1e;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover{
  border-color: #efa95e;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  border-color: #e84f1e;
  background: #e84f1e;
  color: #ffffff;
}

#groupControl{
  display: block;
     text-align: left;
     position: relative;
     cursor: pointer;
     border-radius: 1rem;
     margin-bottom: 0.1875rem;
     min-width: 10rem;
     width: 100%;
     outline: none;
    //  z-index: 0;
     font-family: Montserrat;
     color: #3B4A57;
     background: #F6F7F9;
     border: 0.1875rem solid #0051C2;
    //  padding: 2.0625rem 1.5rem;
     background: #EAEDF1;
     min-height: 4rem;
   }

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus{
  box-shadow: 0 0 0 0.2rem #ef994a;
}

// span.p-multiselect-trigger-icon.p-c.pi.pi-chevron-down{
//   margin-top: -50px;
// }

// .p-multiselect .p-multiselect-trigger{
//   margin-left: 200px;
// }

// .p-multiselect .p-multiselect-label.p-placeholder{
//   color: #3B4A57;
//     font-size: 1rem;
//     font-weight: 600;
//     font-family: Montserrat;
// }

.carrier_input_container .m_d_carrier_dropdown_container.drpGroup .m_d_carrier_dropdown_item:nth-child(1){
  margin-top: -22px;
}

iSUYRt:focus, .iSUYRt:hover {
  background-color: rgb(230, 235, 241);
  color: #e84f1e!important;
  outline: 0px;
}