.boxAnimation {
  margin-left: -20px;
  margin-right: -20px;
  margin-top: 50px;
}
.box {
  margin-left: -25px;
  margin-right: -25px;
  margin-top: 50px;
}
.scroll-table {
  overflow-x: scroll;
  padding-bottom: 50px;
}
.settingButton {
  height: 25px;
  width: 25px;
  margin-right: 0px;
}
.insideButton {
  height: 25px;
  width: 25px;
  margin-right: 6%;
}
.insideButtonCancel {
  height: 15px;
  width: 15px;
  margin-right: 6%;
}
.insideButtonDelay {
  height: 15px;
  width: 15px;
  margin-right: 6%;
  margin-left: -15px;
}
.delayAndCancel {
  margin-top: 30px;
  margin-bottom: -500px;
}
.booked {
  // font-weight: 700;
  color: black;
}
.departed {
  //font-weight: 700;
  color: rgb(240, 163, 163);
}
.delayed {
  //  font-weight: 700;
  color: red;
}
.checked_In {
  // font-weight: 700;
  color: green;
}
.completed {
  // font-weight: 700;
  color: blue;
}
.cancelled {
  // font-weight: 700;
  color: purple;
}
.dock_Unavailable {
  // font-weight: 700;
  color: orange;
}
.delayed_Check_In {
  // font-weight: 700;
  color: rgb(71, 153, 139);
}
.p-datatable-emptymessage {
  td {
    text-align: center !important;
  }
}
.license_Number {
  & > div {
    &:first-child {
      min-height: 68px !important;
      box-sizing: border-box;
      height: 52px;
      border-width: 2px;
      background: #f6f7f9;
      box-shadow: 0 2px 0 #c2c9d1;
    }
  }
}
.date_Input {
  td {
    vertical-align: middle;
    height: 40px !important;
    width: 48px !important;
    &::after {
      width: 48px !important;
      height: 40px !important;
    }
    &.CalendarDay__today {
      &::after {
        left: -2px !important;
        top: -2px !important;
      }
    }
  }
}
// css for calendar component
#root
  > div
  > amplify-authenticator
  > div.dashboard-page
  > div.dashboard-container
  > div
  > div:nth-child(4)
  > div:nth-child(1)
  > div
  > div
  > div
  > div
  > div
  > div
  > div.SingleDatePicker_picker.SingleDatePicker_picker_1.SingleDatePicker_picker__directionLeft.SingleDatePicker_picker__directionLeft_2 {
  margin-top: -100px;
  margin-left: -20px;
}

// ETA column width increase
.ETA_column {
  .p-column-header-content {
    width: 150px;
  }
}

.ETD_column {
  .p-column-header-content {
    min-width: 120px;
  }
}

.p-datepicker table td > span.p-highlight {
  color: white !important;
  background: #e84f1e !important;
}
.editBookingDropdown {
  & + ul {
    max-height: 300px;
  }
}
