.sub_menu_facility {
  margin-left: -25px;

  .sub_menu_links_facility {
    font-family: inherit;
    color: #4a051e;
    border: transparent;
    cursor: pointer;
    background: transparent;
    font-size: 15px;
    font-weight: 500;
    padding: 0px;
    padding-top: 5px;
    padding-bottom: 10px;
    margin-left: 25px;
    margin-top: 10px;
    transition: 0.4s;
    box-sizing: border-box;
    &.active {
      border-bottom: 2.8px solid $orange;
    }
  }
}

.facility_profile_input_container {
  max-height: calc(100vh - 9rem) !important;
  min-width: 60rem !important;
  max-width: 90rem !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  // width: 100%;
  flex-wrap: wrap;

  .facility_profile_input_control {
    width: 48%;
    margin-bottom: 20px;
    & > div {
      &:first-child {
        min-height: unset !important;
        box-sizing: border-box;
        height: 67px;
        border-width: 20px;
        background: #f6f7f9;
        box-shadow: 0 2px 0 #c2c9d1;
      }
    }
  }
  .facility_profile_dropdown_container {
    width: 48%;
    margin-bottom: 20px;
    .facility_profile_dropdown {
      border-width: 2px;
      outline: none;
      padding: 0px;
      height: 67px;
      margin: 0px;
    }
    .facility_profile_dropdown_item {
      padding: 10px 20px;
      span {
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
}

.ewoNQB.facility_input_container {
  max-height: calc(100vh - 9rem) !important;
  min-width: 40rem !important;
  max-width: 90rem !important;
  // width: -webkit-max-content;
  // width: -moz-max-content;
  // width: max-content;
  // min-height: auto;
  // min-height: initial;
  // height: auto;
  // height: initial;
  border-radius: 1.5rem;
  overflow: hidden;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.facilityIdMapUrl {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  width: 150px;
}
